import React, { Fragment } from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Fade, Grid, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import {
  mapSaleDetails,
  mapNumberOptions
} from '../../../../helpers/mappings/mapToOptions';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import { mapReturnCancelCausals } from '../../../../helpers/mappings/mapToOptions';

class ReturnPartially extends React.PureComponent {
  state = {
    items: [],
    nItems: 0
  };

  componentDidMount() {
    if (!this.props.items.length) this.props.newArticle();
  }

  _removeItem = idx => {
    const { items } = this.props;
    const filtered = _filter(items, i => idx != i.idx);
    this.props.setItems({
      items: [...filtered]
    });
    this.props.setArticles([...filtered]);
  };
  _handleSelector = e => {
    const { name, value } = e.target;
    const { items } = this.props;
    const attr = name.split('_')[0];
    const idx = name.split('_')[1];
    const prevItem = _find(items, i => idx == i.idx);
    const withoutPrev = _filter(items, i => idx != i.idx);
    if (attr == 'id') {
      prevItem.amount = -1;
      prevItem.cause = -1;
    }
    const newItems = _orderBy(
      [...withoutPrev, { ...prevItem, [attr]: value }],
      'idx',
      'asc'
    );
    this.props.setArticles(newItems);
    this.props.setItems({ items: newItems });
  };

  _getOptions = (sale, sale_detail_id) => {
    if (!sale.saleDetails[0] || sale_detail_id < 0) return [];
    const selected = _find(sale.saleDetails, i => sale_detail_id == i.id);
    return mapNumberOptions(1, selected.amount);
  };

  _validSaleDetails = sale => {
    let valid = [];
    let totalValue = 0;
    const {
      cardif,
      teleshoppingCardif,
      renewalCardif,
      renewalCardifLayerTwo,
      isB2bSale,
      secureSaleArticleId,
      cardifLayerTwoId,
      isSecureSale
    } = this.props;

    _filter(sale.saleDetails, sd => {
      if (
        ![
          cardif,
          teleshoppingCardif,
          renewalCardif,
          renewalCardifLayerTwo,
          cardifLayerTwoId,
          secureSaleArticleId
        ].includes(sd.article.id)
      ) {
        totalValue += Number(sd.value) + Number(sd.iva);
      }
    });

    totalValue -= Number(sale.downPayment);
    const b2bCase =
      isB2bSale && sale.invoiceValue ? sale.invoiceValue !== totalValue : false;

    valid = _filter(sale.saleDetails, sd => {
      const isVoluntaryInsurance = [
        cardif,
        teleshoppingCardif,
        renewalCardif,
        renewalCardifLayerTwo,
        cardifLayerTwoId
      ].includes(sd.article.id);
      return (
        sd.status == 'delivered' &&
        ((!isSecureSale && !b2bCase) ||
          (isSecureSale && isVoluntaryInsurance) ||
          (b2bCase && isVoluntaryInsurance))
      );
    });
    return valid;
  };

  _getSaleArticles = sale => {
    const { items } = this.props;
    if (!sale.saleDetails[0]) return [''];
    return mapSaleDetails(this._validSaleDetails(sale), items);
  };

  _getAmount = (sale, sale_detail_id) => {
    if (!sale.saleDetails[0] || sale_detail_id < 0) return [];
    const selected = _find(sale.saleDetails, i => sale_detail_id == i.id);
    return mapNumberOptions(1, selected.amount);
  };
  _getCausal = (sale_detail_id, sale) => {
    const sale_detail = _find(sale.saleDetails, a => a.id == sale_detail_id);
    if (!sale_detail) return [];
    const filteredCausalList = _filter(
      mapReturnCancelCausals(
        this.props.revokeSaleCausals,
        this.props.cardif,
        this.props.teleshoppingCardif,
        this.props.renewalCardif,
        this.props.renewalCardifLayerTwo,
        this.props.cardifLayerTwoId
      ),
      i => {
        if (i.allExcept.includes(sale_detail.article.id)) return false;
        if (i.only.includes(sale_detail.article.id)) return true;
        if (i.only.length == 0) return true;
        return false;
      }
    );
    const mappedList = mapListToOptions(filteredCausalList);
    return mappedList;
  };
  render() {
    const { classes, sale } = this.props;
    const { items } = this.props;
    return (
      <Fragment>
        {items.map(a => {
          return (
            <Fade key={a.idx} in={true}>
              <Grid container spacing={8}>
                <Grid item xs={5}>
                  <SimpleSelect
                    required
                    label="Artículo"
                    name={`id_${a.idx}`}
                    options={this._getSaleArticles(sale)}
                    value={a.id}
                    onChange={this._handleSelector}
                    disabled={false}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2}>
                  <SimpleSelect
                    required
                    label="Cant."
                    name={`amount_${a.idx}`}
                    options={this._getAmount(sale, a.id)}
                    value={a.amount}
                    onChange={this._handleSelector}
                    disabled={a.id == -1}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <SimpleSelect
                    required
                    label="Causal de Devolución"
                    name={`cause_${a.idx}`}
                    options={this._getCausal(a.id, sale)}
                    value={a.cause}
                    onChange={this._handleSelector}
                    disabled={a.id == -1}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={1} className={classes.closeButton}>
                  <IconButton
                    disabled={items.length == 1}
                    onClick={() => this._removeItem(a.idx)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Fade>
          );
        })}
        {items.length < this._validSaleDetails(sale).length && (
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={this.props.newArticle}
          >
            {'Añadir artículo'}
          </Button>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  }
});

ReturnPartially.propTypes = {
  sales: PropTypes.object
};
export default withStyles(styles)(ReturnPartially);
